import axios from 'axios';
import * as queryString from "query-string";
import {API_URL, COOKIE_KEY} from "../config/constant";

export const authService = {
    async signIn(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/user/login`,
            data: queryString.stringify(data)
        })
            .then(function (response) {
                return response.data
            }).catch((error) => {
                if (error.data) {
                    return (error.data);
                } else {
                    return false;
                }
            });
    },

    async getUserInfo(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/user/user_info`,
            data: queryString.stringify(data)
        })
            .then(function (response) {
                return response.data
            }).catch((error) => {
                if (error.data) {
                    return (error.data);
                } else {
                    return false;
                }
            });
    },

    async register(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/user/register`,
            data: queryString.stringify(data)
        })
            .then(function (response) {
                return response.data
            }).catch((error) => {
                if (error.data) {
                    return (error.data);
                } else {
                    return false;
                }
            });
    },

    getOtpSMS(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/user/get_otp`,
            data: queryString.stringify(data)
        })
            .then(function (response) {
                return response.data
            }).catch((error) => {
                if (error.data) {
                    return (error.data);
                } else {
                    return false;
                }
            });
    },

    getOtpEmail(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/user/otpSendEmail`,
            data: queryString.stringify(data)
        })
            .then(function (response) {
                return response.data
            }).catch((error) => {
                if (error.data) {
                    return (error.data);
                } else {
                    return false;
                }
            });
    },

    signOut() {
        return localStorage.removeItem(COOKIE_KEY)
    },

    uploadSelfie(data) {
        let formData = new FormData();
        formData.append('image', data.image);
        formData.append('message', data.message);
        formData.append('sticker', data.sticker);

        return axios({
            method: 'POST',
            url: `${API_URL}/user/selfie`,
            data: formData,
            headers: {
                "cache-control": "no-cache",
                "Content-Type": "multipart/form-data",
            }
        })
            .then(function (response) {
                return response.data
            }).catch(() => {
                return false
            });
    },

    uploadSelfieBase64(data){
        return axios({
            method: 'POST',
            url: `${API_URL}/user/upload_img_base64`,
            data: queryString.stringify(data),
            headers: {
                "cache-control": "no-cache"
            }
        })
            .then(function (response) {
                return response.data
            }).catch(() => {
                return false
            });
    },


    getSelfieList(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/user/pledges`,
            data: queryString.stringify(data)
        })
            .then(function (response) {
                return response.data
            }).catch((error) => {
                if (error.data) {
                    return (error.data);
                } else {
                    return false;
                }
            });
    },


    getTotalSelfie() {
        return axios({
            method: 'GET',
            url: `${API_URL}/user/total_pledges`
        })
            .then(function (response) {
                return response.data
            }).catch((error) => {
                if (error.data) {
                    return (error.data);
                } else {
                    return false;
                }
            });
    },
};
