import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import( '../views/Home.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import( '../views/Login.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import( '../views/Register.vue')
    },
    {
        path: '/visionhall-selfie-pledge',
        name: 'visionhall-selfie-pledge',
        component: () => import( '../views/Selfie.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/visionhall-selfie-pledge-dev',
        name: 'visionhall-selfie-pledge-dev',
        component: () => import( '../views/SelfieTest.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    }
});

router.beforeEach(async (to, from, next) => {
    let currentUser = store.state.auth;
    if (to.meta.requiresAuth && !currentUser.isLogged) {
        next({path: '/login'});
    } else {
        next();
    }
});


export default router;
