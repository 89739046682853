export const SET_CURRENT_USER = 'setCurrentUser';
export const GET_CURRENT_USER = 'getCurrentUser';
export const GET_USER_INFO = 'getUserInfo';
export const LOGOUT = 'logout';
export const LOGIN = 'login';
export const REGISTER = 'register';
export const GET_OTP_SMS = 'getOTPSms';
export const GET_OTP_EMAIL = 'getOTPEmail';
export const SET_TOKEN = 'setToken';
export const SET_STATE = 'setState';
export const UPLOAD_SELFIE = 'uploadSelfie';
export const UPLOAD_SELFIE_BASE64 = 'uploadSelfieBase64';
export const GET_SELFIE_LIST = 'getSelfieList';
export const GET_TOTAL_SELFIE = 'getTotalSelfie';
