import {
    GET_SELFIE_LIST, GET_TOTAL_SELFIE
} from "./actions.type";
import {authService} from "../services/authService";
import * as _ from 'underscore'

function initState() {
    return {
        pledges: [
            "Embrace data, not your gut",
            "Shift your focus from quantity to quality",
            "Embrace change and recognize your worth",
            "Cultivate teamwork by fostering interest and passion",
            "Strive for continuous improvement",
            "Focus on the goal, not obstacles",
            "The future is sustainability",
        ],
        sticker: _.shuffle([1, 2, 3, 4, 5, 6])
    }
}

const state = initState();

const actions = {
    async [GET_SELFIE_LIST]({commit}, data) {
        return authService.getSelfieList(data);
    },
    async [GET_TOTAL_SELFIE]({}) {
        return authService.getTotalSelfie();
    }
};

const mutations = {};

const getters = {};

export default {
    state,
    actions,
    mutations,
    getters
};
