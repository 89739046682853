import {
    GET_USER_INFO,
    REGISTER,
    SET_TOKEN,
    LOGIN,
    LOGOUT, GET_CURRENT_USER, GET_OTP_SMS, SET_CURRENT_USER, UPLOAD_SELFIE, GET_OTP_EMAIL, UPLOAD_SELFIE_BASE64
} from "./actions.type";
import {authService} from "../services/authService";
import axios from 'axios';
import {COOKIE_KEY} from "../config/constant";
import Vue from 'vue'

function initState() {
    return {
        isLogged: false,
        token: null,
        user: {}
    }
}

const state = initState();

const actions = {
    async [GET_USER_INFO]({commit}) {
        let result = await authService.getUserInfo();
        commit(GET_USER_INFO, result);
        return result;
    },

    async [LOGIN]({commit}, data) {
        let result = await authService.signIn(data);
        if (result.token) {
            commit(LOGIN, result);
            commit(SET_TOKEN, result.token);
        }
        return result;
    },

    async [LOGOUT]({commit}) {
        let result = await authService.signOut();
        commit(LOGOUT);
        return result;
    },

    async [REGISTER]({commit}, data) {
        let result = await authService.register(data);

        if (result.token) {
            commit(SET_TOKEN, result.token);
        }

        return result;
    },

    async [GET_CURRENT_USER]({commit}) {
        let token = Vue.$cookies.get(COOKIE_KEY);
        if (token) {
            commit(SET_TOKEN, token);
        }

        return token;
    },

    async [GET_OTP_SMS]({commit}, data) {
        return await authService.getOtpSMS(data);
    },

    async [GET_OTP_EMAIL]({commit}, data) {
        return await authService.getOtpEmail(data);
    },

    async [UPLOAD_SELFIE]({commit}, data) {
        return await authService.uploadSelfie(data);
    },

    async [UPLOAD_SELFIE_BASE64]({commit}, data) {
        return await authService.uploadSelfieBase64(data);
    },
};

const mutations = {
    [GET_USER_INFO](state, user) {
        state.user = user;
    },

    [LOGIN](state, user) {
        state.user = user;
        state.isLogged = true;
    },

    [SET_TOKEN](state, token) {
        state.token = token;

        // set header
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        // set session
        let midnight = new Date();
        midnight.setHours(23, 59, 59, 59);
        Vue.$cookies.set(COOKIE_KEY, token, midnight);

        state.isLogged = true;
    },
    [LOGOUT](state) {
        state.user = {};
        state.isLogged = false;

        // remove session
        Vue.$cookies.remove(COOKIE_KEY);
    },
};

const getters = {
    getUser: state => {
        return state.user;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
