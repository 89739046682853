import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
import auth from './auth'
import state from './state'
import selfie from './selfie'


export default new Vuex.Store({
    modules: {
        auth,
        state,
        selfie
    }
})
