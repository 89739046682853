let config;

if (process.env.NODE_ENV === 'production') {
    config = {
        COOKIE_KEY: 'virtualspace',
        API_URL: 'https://api-misc.ddns.net',
        BASE_URL: 'https://misc-webapp.ddns.net'
    };
} else {
    config = {
        API_URL: 'http://localhost/games-event-api',
        BASE_URL: 'http://townhall.misc.com.my',
        COOKIE_KEY: 'virtualspace',
    };
}

module.exports = config;

