import {
    SET_STATE
} from "./actions.type";

function initState() {
    return {
        isLoading: false,
    }
}

const state = initState();

const actions = {
    async [SET_STATE]({commit}, isLoading) {
        commit(SET_STATE, isLoading);
    }
};

const mutations = {
    [SET_STATE](state, isLoading) {
        state.isLoading = isLoading;
    }
};

const getters = {
};

export default {
    state,
    actions,
    mutations,
    getters
};
