<template>
  <div class="transition-screen">
    <video id="vid" autoplay loop>
      <source id="mp4" :src="require(`@/assets/video/misc_wormhole_sea.mp4`)+'?time='+Date.now()" type="video/mp4">
    </video>
  </div>
</template>

<script>
  export default {
    name: 'TransitionScreen',
  }
</script>

<style lang="scss" scoped>
  @import "../assets/css/colors";

  video {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: -1;
    min-width: 100%;
    min-height: 100%;
    width: 100vw;
    height: auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-size: cover;
    background-color: black;
  }
</style>
