import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'sweetalert2/src/sweetalert2.scss'
import VueCookies from 'vue-cookies'
import './assets/css/main.scss'
import VueFullPage from 'vue-fullpage.js'
import {BootstrapVue} from 'bootstrap-vue'
import 'swiper/swiper-bundle.css'
import InfiniteLoading from 'vue-infinite-loading';

import VueTelInput from 'vue-tel-input'

Vue.use(VueTelInput);
Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(BootstrapVue);
Vue.use(VueFullPage);
Vue.use(InfiniteLoading, {});

import {
    GET_CURRENT_USER, GET_USER_INFO,
} from "./store/actions.type";

async function init() {
    // get token
    let user = await store.dispatch(GET_CURRENT_USER);

    if (user) {
        await store.dispatch(GET_USER_INFO);
    }

    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app');
}

init();
