<template>
  <div v-if="isLoading" class="loading-body">
    <TransitionScreen/>
  </div>

  <div v-else>
    <router-view/>
  </div>
</template>


<script>
  import TransitionScreen from './components/TransitionScreen'
  import {mapState} from 'vuex';

  export default {
    name: 'App',
    components: {TransitionScreen},
    computed: {
      ...mapState({
        isLoading: state => state.state.isLoading
      }),
    }
  }
</script>